import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Modal, Paper, TextField, Typography, Grid, InputAdornment, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Papa from 'papaparse';

// Yup validation schema
const validationSchema = Yup.object({
  card_no: Yup.string().required('Card number is required').matches(/^\d+$/, 'Card number must be a number'),
  date: Yup.date().required('Date is required'),
  type_of_fuel: Yup.string().required('Fuel type is required'),
  fuel_usage: Yup.number().required('Fuel usage is required').typeError('Fuel usage must be a number'),
  amount_aud: Yup.number().required('Amount is required').typeError('Amount must be a number'),
  docket_no: Yup.number().integer().required('Docket number is required').typeError('Docket number must be an integer'),
  receipt_no: Yup.string().matches(/^\d{7}$/, 'Receipt number must be 7 digits').required('Receipt number is required'),
  remarks: Yup.string().nullable(),
});

export const AddFuelRecord = () => {
  const [transactions, setTransactions] = useState([]);
  const [filterMonth, setFilterMonth] = useState(new Date().toISOString().substring(0, 7)); // Default to current month
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); // For editing
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    fetchTransactions(); // Load data on mount
  }, []);

  const fetchTransactions = () => {
    axios.get('/api/get-input-transactions').then(res => {
      console.log("api response:", res.data);
      setTransactions(res.data);
      setIsEditMode(false);
    });
  };

  const filteredTransactions = transactions.filter(txn => {
    const [txnDay, txnMonth, txnYear] = txn.date.split('/').map(Number);
    const [filterYear, filterMonthValue] = filterMonth.split('-').map(Number);
    return txnYear === filterYear && txnMonth === filterMonthValue;
  });

  // DataGrid column definitions
  const columns = [
    { field: 'sn', headerName: 'S/N', width: 70 },
    { field: 'card_no', headerName: 'Card Number', width: 150 },
    { field: 'date', headerName: 'Date', width: 130 },
    { field: 'type_of_fuel', headerName: 'Type of Fuel', width: 150 },
    { field: 'fuel_usage', headerName: 'Fuel Usage (Litres)', width: 180 },
    { field: 'amount_aud', headerName: 'Amount (AUD)', width: 150 },
    { field: 'receipt_no', headerName: 'Receipt Number', width: 150 },
    { field: 'docket_no', headerName: 'Docket Number', width: 150 },
    { field: 'remarks', headerName: 'Remarks', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEditClick(params.row)}
        >
          Edit
        </Button>
      ),
    },
  ];

  console.log("filtered:", filteredTransactions);

  // Prepare rows for DataGrid
  const rows = filteredTransactions.map((txn, idx) => ({
    id: txn.sn, // 'id' is required by DataGrid
    sn: idx + 1,
    card_no: txn.card_no,
    date: txn.date,
    type_of_fuel: txn.type_of_fuel,
    fuel_usage: txn.fuel_usage,
    amount_aud: txn.amount_aud,
    receipt_no: txn.receipt_no,
    docket_no: txn.docket_no,
    remarks: txn.remarks,
  }));

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(transactions); // Convert data to CSV format
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'fuel_transactions.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImportCSV = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          let newTransactions = result.data;
  
          // Filter out any transactions without a valid 'sn'
          newTransactions = newTransactions.filter(
            (transaction) => transaction.sn && transaction.sn.trim() !== ''
          );
  
          newTransactions = newTransactions.map((transaction) => ({
            sn: transaction['sn'],
            card_no: transaction['card_no'],
            date: transaction['date'],
            type_of_fuel: transaction['type_of_fuel'],
            fuel_usage: transaction['fuel_usage'],
            amount_aud: transaction['amount_aud'],
            receipt_no: transaction['receipt_no'],
            docket_no: transaction['docket_no'],
            remarks: transaction['remarks'],
          }));
  
          console.log(newTransactions);
  
          // Send the parsed and reordered CSV data to the backend
          axios
            .post('/api/bulk-upload', newTransactions)
            .then((response) => {
              alert(response.data.message); // Notify the user of successful upload
              fetchTransactions(); // Refresh the transaction list from the server
            })
            .catch((error) => {
              console.error('Error uploading CSV data:', error.response.data);
              alert('Failed to upload CSV data.');
            });
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          alert('Failed to parse CSV file.');
        },
      });
    }
  };  

  const handleEditClick = (row) => {
    setSelectedRecord(row);
    setIsEditMode(true);
    setIsModalOpen(true); // Open modal for editing
  };

  const handleAddClick = () => {
    setSelectedRecord(null);
    setIsEditMode(false); // Set to add mode
    setIsModalOpen(true);
  };  

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom paddingBottom='20px'>Add Fuel Record</Typography>

      <Grid container spacing={2} alignItems="center" marginBottom="20px">
        {/* Month filter on the left */}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            type="month"
            value={filterMonth}
            onChange={e => setFilterMonth(e.target.value)}
            label="Filter by Month"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {/* Buttons on the right */}
        <Grid item xs={12} sm={6} md={9}>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }} 
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            gap={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddClick}
              fullWidth={false}
            >
              Add Fuel Record
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleDownloadCSV}
              fullWidth={false}
            >
              Download CSV
            </Button>

            <Button
              variant="contained"
              component="label"
              color="success"
              fullWidth={false}
            >
              Import CSV
              <input
                type="file"
                accept=".csv"
                onChange={handleImportCSV}
                hidden
              />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          style={{
            backgroundColor: 'white',
            padding: '30px',
            margin: 'auto',
            width: '100%',
            maxWidth: '800px',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <FuelRecordForm
            onClose={() => setIsModalOpen(false)}
            refreshTransactions={fetchTransactions}
            initialData={selectedRecord} // Pass selected row for editing
            isEditMode={isEditMode}
          />
        </Box>
      </Modal>

      <Paper style={{ height: 400, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5, 10, 20]} />
      </Paper>
    </div>
  );
};

// Updated FuelRecordForm to support editing
const FuelRecordForm = ({ onClose, refreshTransactions, initialData, isEditMode }) => {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    axios.get('/card.csv').then(response => {
      Papa.parse(response.data, {
        header: true,
        complete: results => {
          setCardData(results.data); // Set parsed card data
        },
        error: (error) => {
          console.error("Error parsing CSV:", error); // Log parsing errors
        }
      });
    });
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    };

    const payload = {
      card_no: `RSAF ${values.card_no}`,
      date: formatDate(values.date),
      type_of_fuel: values.type_of_fuel,
      fuel_usage: parseFloat(values.fuel_usage),
      amount_aud: parseFloat(values.amount_aud),
      receipt_no: values.receipt_no,
      docket_no: values.docket_no,
      remarks: values.remarks,
      ...(values.sn && { sn: values.sn }),
    };

    const apiEndpoint = isEditMode ? '/api/edit-fuel-record' : '/api/add-fuel-record'; // Conditional API endpoint
    const requestMethod = isEditMode ? 'post' : 'post'; // Conditional request method

    console.log(payload);

    axios[requestMethod](apiEndpoint, payload).then(res => {
      alert(res.data.message);
      refreshTransactions(); // Trigger data refresh after successful submission
      onClose();
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const getCardUnits = (cardNo) => {
    if (!Array.isArray(cardData)) return null;

    const cardRecord = cardData.find(card => card['CARD NO.'] === `${cardNo}`);
    return cardRecord ? {
      F1: cardRecord['F1 UNIT'] || 'NIL',
      F2: cardRecord['F2 UNIT'] || 'NIL',
      F3: cardRecord['F3 UNIT'] || 'NIL',
      F4: cardRecord['F4 UNIT'] || 'NIL',
    } : null;
  };

  return (
    <Formik
      initialValues={{
        sn: (initialData?.id ? initialData.id : '-1'),
        card_no: (initialData?.card_no ? initialData.card_no.replace(/^RSAF\s*/, '') : ''),
        date: initialData?.date ? initialData.date.split('/').reverse().join('-') : new Date().toISOString().substring(0, 10),
        type_of_fuel: initialData?.type_of_fuel || 'Petrol',
        fuel_usage: initialData?.fuel_usage || '',
        amount_aud: initialData?.amount_aud || '',
        docket_no: initialData?.docket_no || '',
        receipt_no: initialData?.receipt_no || '',
        remarks: initialData?.remarks || '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Box mb={3}>
                <Field
                  name="card_no"
                  as={TextField}
                  label="Card No"
                  fullWidth
                  InputProps={{ startAdornment: <InputAdornment position="start">RSAF</InputAdornment> }}
                  onChange={e => setFieldValue('card_no', e.target.value)}
                />
                <ErrorMessage name="card_no" component="div" style={{ color: 'red' }} />
              </Box>

              <Box mb={3}>
                <Field
                  name="date"
                  as={TextField}
                  type="date"
                  label="Date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorMessage name="date" component="div" style={{ color: 'red' }} />
              </Box>

              <Box mb={3}>
                <Field
                  name="type_of_fuel"
                  as={TextField}
                  select
                  label="Type of Fuel"
                  fullWidth
                >
                  <MenuItem value="UNLEADED PETROL">Petrol</MenuItem>
                  <MenuItem value="DIESEL">Diesel</MenuItem>
                </Field>
                <ErrorMessage name="type_of_fuel" component="div" style={{ color: 'red' }} />
              </Box>

              <Box mb={3}>
                <Field
                  name="fuel_usage"
                  as={TextField}
                  label="Fuel Usage"
                  fullWidth
                  InputProps={{ endAdornment: <InputAdornment position="end">Litres</InputAdornment> }}
                />
                <ErrorMessage name="fuel_usage" component="div" style={{ color: 'red' }} />
              </Box>

              <Box mb={3}>
                <Field
                  name="amount_aud"
                  as={TextField}
                  label="Amount"
                  fullWidth
                  InputProps={{ startAdornment: <InputAdornment position="start">AUD$</InputAdornment> }}
                />
                <ErrorMessage name="amount_aud" component="div" style={{ color: 'red' }} />
              </Box>

              <Box mb={3}>
                <Field
                  name="docket_no"
                  as={TextField}
                  label="Docket Number"
                  fullWidth
                />
                <ErrorMessage name="docket_no" component="div" style={{ color: 'red' }} />
              </Box>

              <Box mb={3}>
                <Field
                  name="receipt_no"
                  as={TextField}
                  label="Fuel Receipt Number"
                  fullWidth
                />
                <ErrorMessage name="receipt_no" component="div" style={{ color: 'red' }} />
              </Box>

              <Box mb={3}>
                <Field
                  name="remarks"
                  as={TextField}
                  label="Remarks"
                  fullWidth
                  multiline
                  rows={3}
                />
                <ErrorMessage name="remarks" component="div" style={{ color: 'red' }} />
              </Box>

              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>
                Submit
              </Button>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box mt={3} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
                <Typography variant="h6">Card Information</Typography>
                {values.card_no ? (
                  <div>
                    <Typography>Units for RSAF {values.card_no}:</Typography>
                    {getCardUnits(values.card_no) ? (
                      <Typography>
                        F1: {getCardUnits(values.card_no).F1} <br />
                        F2: {getCardUnits(values.card_no).F2} <br />
                        F3: {getCardUnits(values.card_no).F3} <br />
                        F4: {getCardUnits(values.card_no).F4}
                      </Typography>
                    ) : (
                      <Typography>No units available</Typography>
                    )}
                  </div>
                ) : (
                  <Typography>N/A</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};